import React from 'react'
import './Rlsection.css'
import rightImg from '../../assets/tea-left-img.png'


function RLsection() {
  return (
    <>
<section className="RL-section">
<div className='text-center'>
   {/* <h2 className='text-heading'>WHO WE ARE ..?</h2> */}
    </div>

    <div className="col-lg-12">
    <div className="row">
    
    <div className="col-lg-5 text-center">     
  <div className="card">     
 <img className="img-fluid right-img"  src={rightImg} alt="" />
  </div>

</div>
<div className="col-lg-7">

  <div className="content-container">
  <h2>Wellcome to <span className='red-text'>i Tea Party</span> </h2>
  <h3>OUR VISION</h3>
  <p>
"Our mission is to bring joy and promote healthy lifestyles to our customers with every cup of tea we serve."</p>

  <h3>OUR MISSION</h3>
  <p>
"Our mission is to bring joy and promote healthy lifestyles to our customers with every cup of tea we serve."</p>

  <button className='btn btn-secondary'>OUR PRODUCTS</button>
  </div>

</div>
</div>
</div>



</section>

    </>
  )
}

export default RLsection