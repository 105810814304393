import React from 'react'
import {Link} from 'react-router-dom'
import './hero.css'
function Hero() {
  return (
    <section className="hero">

        <div className="container">
        <div className="hero-inner">

          <div className="content">
          <h1>TRADITION TEA POWDER</h1>
            <h2>i TEA PARTY</h2>
            <p>
             
"Our mission is to bring joy and promote healthy lifestyles to our customers with every cup of tea we serve."
              
            </p>
           <Link to={'shop'} >
           <button className='btn btn-primary' type='button'>SHOP</button>
           </Link> 
          </div>
            
        </div>
        </div>
        
    </section>
  )
}

export default Hero