import { createSlice, current } from '@reduxjs/toolkit';
const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    cartItems:[]
  },
  reducers: {
    addToCart: (state, action) => {
    const  cartproduct   = action.payload[0];
     const  qty   = action.payload[1];

  const newcartProduct = {
    productId:cartproduct.productId,
    productTitle:cartproduct.productTitle,
    productPrice:cartproduct.productPrice,
    qty:qty
  }

     const alreadyexist = current(state).cartItems.find(item => item.productId === cartproduct.productId)


     if (alreadyexist) {
      const existingProductIndex = current(state).cartItems.findIndex(item => item.productId === cartproduct.productId);

      if (existingProductIndex !== -1) {
        state.cartItems[existingProductIndex].qty += qty;
      } else {
        state.cartItems.push(newcartProduct);
      }
    }
    else{
      state.cartItems.push(newcartProduct);
    }

    },
    removeFromCart: (state, action) => {
      state.cartItems.splice(action.payload, 1);
    }
  }
});

export const { addToCart, removeFromCart } = cartSlice.actions;
export const getCartstate= (state)=> state.cart.cartItems;
export default cartSlice.reducer;