import React from 'react'
import { ImArrowRight } from 'react-icons/im'
import why from './hist.jpg'
function WhySection() {
    return (
        <>
       
            <div className="row mb-5">
                

                
                <div className="col-lg-6">
                    <div className="text-start mt-5">

                        <h2 className='mb-3'>Our<span className='color-primary'> History </span></h2>
                        <div className="d-flex align-items-start justify-space-between">
                          
                            <div className="point p-2">
                                <div className='mt-3 ml-3'>
                                    
                                    <p className='text-large-second color-primary'> 
                                    "Our history is rooted in collaboration with farmers in the lower Himalayas, Chennai, Coimbatore, and Coorg regions of India.
 We work directly with local farmer families,
 practicing fair trade with them "
</p>
                                    
                                    
                                    </div>
                            </div>
                        </div>

                      

                    </div>
                </div>

                <div className="col-lg-6">

                    
                 
                    <img src={why} alt="" className='img-fluid rounded mx-auto d-block' />
                      
                
                </div>

                </div>

            
            
        </>
    )
}

export default WhySection