import React, { useEffect, useState } from 'react'
import axios from "axios";

import './products.css'
import ProductCard from './ProductCard'
import { productsData } from './productsData'

function Products() {

    const [products, setProducts] = useState([]);
    const [isLoading, setisLoading] = useState(true)


    const fetchProducts = async () => {

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_GET_ALL_PRODUCTS}`);
            setProducts(response.data.data)
            setisLoading(false)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        fetchProducts()

    }, []);



    return (
        <>
            <section className="RL-section pb-5">
                <div className='text-center'>
                    <h3 className='text-heading'>OUR PRODUCTS</h3>
                </div>

                <div className="col-lg-12">
                    <div className="row">
                        {
                            isLoading && <div className="spinner-border mx-auto" role="status">
                            <span className="visually-hidden">Loading.</span>
                        </div>
                        }
                        

                        {products.map((product, index) => {

                            return <ProductCard key={index} data={product} />
                        })}



                    </div>
                </div>



            </section>

        </>
    )
}

export default Products