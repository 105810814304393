import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';
import { removeFromCart, getCartstate } from '../cartSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { useLocation } from 'react-router-dom'
function CheckOut() {
    const location = useLocation()
    const { cartItems, subtotal } = location.state
    const globcartItems = useSelector(getCartstate)
    const [OrderInfo, setOrderInfo] = useState({
        email: "",
        firstName: "",
        LastName: "",
        Address: "",
        Apartment: "",
        City: "",
        State: "",
        Pincode: "",
        Phone: "",
    });
    const toastObj = {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    }
    const handleChange = (event) => {
        setOrderInfo({ ...OrderInfo, [event.target.name]: event.target.value });
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        
        if (OrderInfo.email == '' ||
            OrderInfo.firstName == '' ||
            OrderInfo.LastName == '' ||
            OrderInfo.Address == '' ||
            OrderInfo.Apartment == '' ||
            OrderInfo.City == '' ||
            OrderInfo.State == '' ||
            OrderInfo.Pincode == '' ||
            OrderInfo.Phone == '') {
            toast.error("Please Fill all * Fields", toastObj);
        }
        else {
            var date = new Date().toLocaleString();
            const OrderObj = {
                orderId: uuidv4(),
                userDetails: JSON.stringify(OrderInfo),
                cartItems: JSON.stringify(cartItems),
                totalAmount: subtotal,
                pending: 'Y',
                DOO: date
            }
            axios.post(`${process.env.REACT_APP_API_PLACE_ORDER}`, OrderObj).then((result) => {
                const resCode = result.data.code
                if (resCode == 200) {
                    toast.success("Order Placed..!!", toastObj);
                } else {
                    toast.error("SomeThing Went Wrong", toastObj);
                }
            })

        }
    };
    const clear = (event) => {
        event.preventDefault();
        setOrderInfo({
            email: "",
            firstName: "",
            LastName: "",
            Address: "",
            Apartment: "",
            City: "",
            State: "",
            Pincode: "",
            Phone: "",
        });
    };


    return (

        <div className='main-conatiner container'>

            <section className="RL-section">

                <div className="row d-flex justify-content-center">
                    <div className="col-lg-12">
                        {globcartItems.length == '' || undefined ? <NoDataCard /> :
                            <div className="card d-block cart-card">
                                <div className="card-header bg-w ">
                                    <div className="card-title">
                                        <h3>Check Out</h3>
                                    </div>
                                </div>
                                <div className="card-body">

                                    <div className="row">
                                        <div className="col-lg-6">
                                            <form onSubmit={handleSubmit}>

                                                <div className="form-group mb-4">
                                                    <label>Email <span className='text-red'>*</span></label>

                                                    <input name="email" type="email" onChange={handleChange} placeholder="name@example.com" className="form-control" value={OrderInfo.email} />
                                                </div>

                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group mb-4">
                                                            <label>First Name <span className='text-red'>*</span></label>

                                                            <input name="firstName" type="text" onChange={handleChange} className="form-control" placeholder="" value={OrderInfo.firstName} />
                                                        </div>
                                                    </div>
                                                    <div className="col">

                                                        <div className="form-group mb-4">
                                                            <label>Last Name <span className='text-red'>*</span></label>

                                                            <input name="LastName" type="text" onChange={handleChange} className="form-control" placeholder="" value={OrderInfo.LastName} />
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="form-group mb-4">
                                                    <label>Address <span className='text-red'>*</span></label>
                                                    <input name="Address" type="text" onChange={handleChange} className="form-control" value={OrderInfo.Address} />
                                                </div>

                                                <div className="form-group mb-4">
                                                    <label>Apartment <span className='text-red'>*</span></label>
                                                    <input name="Apartment" type="text" onChange={handleChange} className="form-control" placeholder="" value={OrderInfo.Apartment} />
                                                </div>

                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group mb-4">
                                                            <label>City <span className='text-red'>*</span></label>
                                                            <input name="City" type="text" onChange={handleChange} className="form-control" placeholder="" value={OrderInfo.City} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group mb-4">
                                                            <label>State <span className='text-red'>*</span></label>
                                                            <select name="State" onChange={handleChange} className="form-control form-select" value={OrderInfo.State}  >
                                                                <option value="Andhra Pradesh">Andhra Pradesh</option>
                                                                <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                                                                <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                                                <option value="Assam">Assam</option>
                                                                <option value="Bihar">Bihar</option>
                                                                <option value="Chandigarh">Chandigarh</option>
                                                                <option value="Chhattisgarh">Chhattisgarh</option>
                                                                <option value="Dadar and Nagar Haveli">Dadar and Nagar Haveli</option>
                                                                <option value="Daman and Diu">Daman and Diu</option>
                                                                <option value="Delhi">Delhi</option>
                                                                <option value="Lakshadweep">Lakshadweep</option>
                                                                <option value="Puducherry">Puducherry</option>
                                                                <option value="Goa">Goa</option>
                                                                <option value="Gujarat">Gujarat</option>
                                                                <option value="Haryana">Haryana</option>
                                                                <option value="Himachal Pradesh">Himachal Pradesh</option>
                                                                <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                                                                <option value="Jharkhand">Jharkhand</option>
                                                                <option value="Karnataka">Karnataka</option>
                                                                <option value="Kerala">Kerala</option>
                                                                <option value="Madhya Pradesh">Madhya Pradesh</option>
                                                                <option value="Maharashtra">Maharashtra</option>
                                                                <option value="Manipur">Manipur</option>
                                                                <option value="Meghalaya">Meghalaya</option>
                                                                <option value="Mizoram">Mizoram</option>
                                                                <option value="Nagaland">Nagaland</option>
                                                                <option value="Odisha">Odisha</option>
                                                                <option value="Punjab">Punjab</option>
                                                                <option value="Rajasthan">Rajasthan</option>
                                                                <option value="Sikkim">Sikkim</option>
                                                                <option value="Tamil Nadu">Tamil Nadu</option>
                                                                <option value="Telangana">Telangana</option>
                                                                <option value="Tripura">Tripura</option>
                                                                <option value="Uttar Pradesh">Uttar Pradesh</option>
                                                                <option value="Uttarakhand">Uttarakhand</option>
                                                                <option value="West Bengal">West Bengal</option>
                                                            </select>
                                                            {/* <input name="State" type="text" onChange={handleChange} className="form-control" placeholder="" value={OrderInfo.State} /> */}
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group mb-4">
                                                            <label>Pincode <span className='text-red'>*</span></label>
                                                            <input name="Pincode" type="text" onChange={handleChange} className="form-control" placeholder="" value={OrderInfo.Pincode} />
                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="form-group mb-4">
                                                    <label>Phone <span className='text-red'>*</span></label>
                                                    <input name="Phone" type="text" onChange={handleChange} className="form-control" placeholder="" value={OrderInfo.Phone} />
                                                </div>
                                                {/* <div className="form-group mb-4">
                                                    <label>Your Message</label>
                                                    <textarea name="message" value={mailInfo.message} onChange={handleChange} className="form-control" rows="3" maxLength="300" autoComplete="off" spellCheck="false" ></textarea>
                                                </div> */}

                                                <button type="submit" className="btn btn-primary btn-main">Place Order</button>

                                                <button type="button" onClick={clear} className="btn btn-primary btn-main-danger m-2">Clear</button>



                                            </form>

                                        </div>
                                        <div className="col-lg-6">
                                            <div className="card checkoutItems" >
                                                <div className="card-header bg-w">
                                                    <h4>Order Summary</h4>
                                                </div>
                                                <div className="card-body">
                                                    {
                                                        globcartItems.map((x) =>


                                                            <ListCard key={x.productId} data={x} />

                                                        )
                                                    }



                                                </div>
                                                <div className="card-footer bg-w text-end">
                                                    <h5>Total : &#8377; {subtotal}.00 INR</h5>
                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                </div>
                                {/* <div className="card-footer d-flex justify-content-between bg-w">



                                    <button type='button' className='btn btn-success'>Place Order</button>

                                </div> */}
                            </div>


                        }


                    </div>
                    <ToastContainer />
                </div>

            </section>




        </div>


    )
}

export default CheckOut




function NoDataCard() {
    return (
        <>


            <div className="card d-block cart-card">

                <div className="card-body">

                    <div className="row text-center">
                        <div className="col-lg-12">

                            <h4>Add some Products ...!!</h4>

                        </div>
                    </div>


                </div>

            </div>

        </>
    )
}


function ListCard({ data }) {
    return (
        <>


            <div className="card d-block cart-card ">

                <div className="card-body d-flex align-items-center justify-content-between text-left checkout">


                    <h5>{data.productTitle}</h5>
                    <h5>&#8377; {data.productPrice}.00 x {data.qty}</h5>

                </div>

            </div>

        </>
    )
}

