import React from 'react'
import { useState } from 'react';
import { QuantityPicker } from 'react-qty-picker';
import { useSelector, useDispatch } from 'react-redux';
import { addToCart } from '../../cartSlice';
import './ProductDescCard.css'



function ProductDescCard({ singleproduct }) {

  const [qty, setQty] = useState(1)
  const dispatch = useDispatch();

  const {productId, productTitle, productDescription, productPrice, coverImageUrl, HowToMakes, inStock } = singleproduct;
 

 
  return (
    <>
      <section className="RL-section">
      <div className="row">
        <div className="col-lg-12">
          
          <div className="row">
          <div className="col-lg-5 text-left">
              <div className="card">
                <img className="img-fluid right-img-loc" src={coverImageUrl} alt="" />
              </div>

            </div>
            <div className="col-lg-7">

              <div className="content-container product">
                <h3>{productTitle}</h3>


                <div className="Ingreadents">
                  <h5>Ingredients:</h5>

                  <div className='chip'>
                    Mint
                  </div>
                  <div className='chip'>
                    Ginger
                  </div>
                  <div className='chip'>
                    Lemon grass
                  </div>
                  <div className='chip'>
                    Cinnamon
                  </div>

                </div>

                <h4 className='mt-4 mb-2'>&#8377;{productPrice}.00 INR  </h4>
                <span className='inc'>Including taxes /-</span>
                <div className='mt-3'>
                
                  <QuantityPicker min={1} max={5} value={1} width='8rem' smooth onChange={(value) => { setQty(value) }} />
                  
                  </div>
                <br />
                <button className='btn btn-success' onClick={() => dispatch(addToCart([singleproduct,qty]))}>ADD TO CART</button>
              </div>
            </div>

          </div>

           
          </div>
        </div>


        <div className="row">

          <div className="aboutSection">
            <h2>About</h2>

            <p>{productDescription}</p>

            <div className="col-lg-5">
              <div className="card howTomake" >
                <div className="card-header">
                  <h4> How to make</h4>
                </div>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">250 ml of hot water</li>
                  <li className="list-group-item">3 to 5 butterfly peas</li>
                  <li className="list-group-item">Sugar or Honey</li>
                  <li className="list-group-item">2 drops of lemon</li>

                </ul>
              </div>
            </div>

          </div>

        </div>


      </section>

    </>
  )
}

export default ProductDescCard