import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import   store from './store';
import './index.css';
import ScrollToTop from './scrollToTop';
import App from './App';

import { BrowserRouter } from 'react-router-dom';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 
    <Provider store={store}>
 
    <BrowserRouter>
    <ScrollToTop />
    <App />
    </BrowserRouter>
    </Provider>
   
  
);
