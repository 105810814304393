import { Route, Routes } from 'react-router-dom';
import './App.css';
import Navbar from './components/NavBar';

import Home from './components/Home';
import Shop from './components/Shop';
import About from './components/About';

import ProductDescPage from './components/ProductDescPage';
import Cart from './components/Cart';
import Contact from './components/Contact';
import CheckOut from './components/CheckOut';
import Footer from './components/Footer/Footer';



function App() {
  return (
    <>
      <div className="App">
        <div className="container-fluid main-container">
          <Navbar />
        </div>
      </div>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='shop' element={<Shop />} /> 
        <Route path='about' element={<About />} /> 

        <Route path='/:productid' element={<ProductDescPage />} />

        <Route path='shop/:productid' element={<ProductDescPage />} />
        <Route path='contact' element={<Contact />} /> 
        <Route path='cart' element={<Cart />} /> 
        <Route path='cart/checkout' element={<CheckOut />} /> 


        
         
      </Routes>
      <Footer/>
    </>
  );
}

export default App;
