import React from 'react'
import logo from './logo.png'
import { BsFacebook, BsInstagram, BsTwitter } from 'react-icons/bs'
import './footer.css'
function Footer() {
	return (
		<>

			<section class="bg-light text-light bg-w">
				<div class="container pt-5 pb-2">
					<div class="row">
						<div class="col-lg-6">
							<div class="lc-block mb-4">

								<img class="img-fluid" alt="logo" src={logo} />



							</div>
							<div class="lc-block small">
								<div >
									<p>

										"Our mission is to bring joy and promote healthy lifestyles to our customers with every cup of tea we serve."
									</p>
								</div>
							</div>


						</div>

						<div class="col-lg-3 ">
							<div class="lc-block mb-4">
								<div>
									<h4>Follow Us</h4>
								</div>
							</div>

							<div class="lc-block small">
								<BsFacebook className='icon-font-basic color-black m-2' />
								<BsInstagram className='icon-font-basic color-black m-2' />
								<BsTwitter className='icon-font-basic color-black m-2' />

							</div>

						</div>
					</div>
				</div>
				<div class="py-2 container">
					<div class="row">
						<div class="col-6 small">
							<div class="lc-block">
								<div editable="rich">
									<p>Copyright © iTea Party - 2023</p>
								</div>
							</div>

						</div>

					</div>
				</div>
			</section>

		</>
	)
}

export default Footer