import React from 'react'
import { Link} from 'react-router-dom';
import productImg from './demo-img.png'
function ProductCard({data}) {

    const {productId,eachqty,productTitle ,productDesc,productPrice,HowToMakes,inStock,coverImageUrl} = data
  return (
    
    <div className="col-lg-4 text-center">
      <Link className='card-route' to={`${productId}`}>
      <div className="card productCard">
        
        <div className="productImage">
        <img className='img-fluid rounded' src={coverImageUrl} alt="" />
        </div>
          <div className="productContent text-left">
          <h4>{productTitle} - {eachqty}</h4>
          <div className="pricediv">
          <h5>&#8377;{productPrice}.00 INR</h5>
          </div>
          
        {inStock =='false' && <div className="stockBadge">
            Out of Stock
        </div> } 
        {/* <button className='btn btn-success'>More</button> */}
          </div>
    </div>

      </Link>
   

</div>
  )
}

export default ProductCard