import React from 'react'
import Products from './Products'

function Shop() {
  return (
    <div className='main-conatiner container'>
        <Products/>
    </div>
  )
}

export default Shop