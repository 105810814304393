import React from 'react'
import Img1 from './freeDelivery.png'
import Img2 from './premium.png'
import Img3 from './customer.png'
import Img4 from './100-percent.png'


function FeatureCard() {
    return (
        <section className='RL-section services'>

            <div className="container">


                <div className="col-lg-12 d-flex justify-content-center text-center">
                    <div className="row">


                        <div className="col-lg-3">
                            <div className="card service_card text-center">
                                <div className="service_card_content text-center">

                                    <img src={Img4} alt="" />
                                    <p>
                                        Natural
                                    </p>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-3">

                            <div className="card service_card text-center">
                                <div className="service_card_content text-center">

                                    <img src={Img1} alt="" />
                                    <p>
                                        Free Delivery
                                    </p>
                                </div>

                            </div>

                        </div>
                        <div className="col-lg-3">
                            <div className="card service_card text-center">
                                <div className="service_card_content text-center">

                                    <img src={Img2} alt="" />
                                    <p>
                                        Premium Quality
                                    </p>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="card service_card text-center">
                                <div className="service_card_content text-center">

                                    <img src={Img3} alt="" />
                                    <p>
                                        24/7 Support
                                    </p>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default FeatureCard