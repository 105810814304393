import React, { useEffect, useState } from 'react'
import axios from "axios";
import { useParams } from 'react-router-dom';
import ProductDescCard from './ProductDescCard';

function ProductDescPage() {



  const [singleproduct, setSingleProduct] = useState({});
  const [isLoading, setisLoading] = useState(true)
  const { productid } = useParams()


  const getSingleProduct = async () => {

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_GET_PRODUCT_BY_ID}`, { "productId": productid })
      setSingleProduct(JSON.parse(response.data.data))
      setisLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getSingleProduct();

  }, []);


  return (
    <div className='main-conatiner container'>
      {
        isLoading && <div className="spinner-border mx-auto" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      }

      <ProductDescCard singleproduct={singleproduct} />


    </div>
  )


}

export default ProductDescPage