import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import {AiOutlineDelete} from 'react-icons/ai'
import {Link,useNavigate} from 'react-router-dom'

import { removeFromCart, getCartstate } from '../cartSlice';

function Cart() {
  const dispatch = useDispatch();

  const cartItems = useSelector(getCartstate)
  let subtotal = 0;
  cartItems.forEach(item => {
    subtotal += item.productPrice * item.qty;
  });

  const handleRemoveFromCart = productId => {
    dispatch(removeFromCart(productId));
  };

  return (

    <div className='main-conatiner container min-vh-100'>

      <section className="RL-section">
       
          <div className="row d-flex justify-content-center">

        
          <div className="col-lg-12 col-sm-6 col-md-10">
            <div className="card d-block cart-card">
              <div className="card-header bg-w ">
                <div className="card-title">
                  <h3>Cart</h3>
                </div>
              </div>


              <div className="cart-body">
             


                <div className="table-responsive">
                <table className='table'>
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Quantity</th>
                      <th>Price</th>
                      <th>Subtotal</th>
                      
                    </tr>
                  </thead>
                  <tbody>

                    { cartItems.length == '' || undefined ? <p className='text-center'>Cart is empty</p> : cartItems.map(item => (
                      <tr key={item.productId}>
                        <td>{item.productTitle}</td>
                        <td>{item.qty}</td>
                        <td>&#8377; {item.productPrice}</td>
                        <td>&#8377; {item.productPrice * item.qty}</td>
                        <td>
                        <AiOutlineDelete onClick={() => handleRemoveFromCart(item.id)} size={24}/>
                          {/* <button className='btn btn-danger' onClick={() => handleRemoveFromCart(item.id)}>
                            Remove
                          </button> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                </div>
               
              </div>
              <div className="card-footer d-flex justify-content-between bg-w">
              <h4 className='d-inline'>Total: &#8377;{subtotal}.00 INR</h4>  
             { cartItems.length != '' &&  <Link to='checkout' state={{ subtotal: subtotal,cartItems:cartItems }}>
              <button className='btn btn-success'>Check Out</button>
              </Link>}
              
              

              </div>
            </div>
          </div>

        </div>

      </section>




    </div>




  );
};

export default Cart





function CartCard() {
  return (
    <>
     <div class="card mb-3 d-block">
                  <div class="card-body">
                    <div class="d-flex justify-content-between">
                      <div class="d-flex flex-row align-items-center">
                        
                        <div class="lg-6 ms-3">
                          <h5>Iphone 11 pro</h5>
                          <p class="small mb-0">256GB, Navy Blue</p>
                        </div>
                      </div>
                      <div class="d-flex flex-row align-items-center">
                        <div >
                          <h5 class="fw-normal mb-0">2</h5>
                        </div>
                        <div >
                          <h5 class="mb-0">$900</h5>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
    </>
  )
}

<AiOutlineDelete size={24}/>



function CartMainSection() {
  return (
    <>
    <div class="row d-flex justify-content-center align-items-center h-100">

  
    <div class="card mb-4">
          <div class="card-body p-4">

            <div class="row align-items-center">
              <div class="col-md-2 d-flex justify-content-center">
                <div>
                  <p class="small text-muted mb-4 pb-2">Name</p>
                  <p class="lead fw-normal mb-0">iPad Air</p>
                </div>
              </div>
              
              <div class="col-md-2 d-flex justify-content-center">
                <div>
                  <p class="small text-muted mb-4 pb-2">Quantity</p>
                  <p class="lead fw-normal mb-0">1</p>
                </div>
              </div>
              <div class="col-md-2 d-flex justify-content-center">
                <div>
                  <p class="small text-muted mb-4 pb-2">Price</p>
                  <p class="lead fw-normal mb-0">$799</p>
                </div>
              </div>
              <div class="col-md-2 d-flex justify-content-center">
                <div>
                  <p class="small text-muted mb-4 pb-2">Total</p>
                  <p class="lead fw-normal mb-0">$799</p>
                </div>
              </div>
              <div class="col-md-2 d-flex justify-content-center">
                <div>
                <AiOutlineDelete size={24}/>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="card mb-5">
          <div class="card-body p-4">

            <div class="float-end">
              <p class="mb-0 me-5 d-flex align-items-center">
                <span class="small text-muted me-2">Order total:</span> <span
                  class="lead fw-normal">$799</span>
              </p>
            </div>

          </div>
        </div>

        <div class="d-flex justify-content-end">
          <button type="button" class="btn btn-light btn-lg me-2">Continue shopping</button>
          <button type="button" class="btn btn-primary btn-lg">Add to cart</button>
        </div>
        </div>
    </>
  )
}
