import React from 'react'
import FeatureCard from './FeatureCard';
import Hero from './Hero';

import Products from './Products';
import RLsection from './RLsection';
import WhySection from '../components/WhySection/WhySection'
function Home() {
  return (
    <>
     <Hero />
        <div className="clearfix"></div>
        <div className="container">
          <RLsection />

        </div>
        <div className="clearfix"></div>

        <div className='container-fluid large-container'>
          <FeatureCard/>
        </div>
        <div className='container '>
          <Products/>
        </div>
        <div className='container '>
          <WhySection/>
        </div>


    </>
  )
}

export default Home