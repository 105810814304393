import React from 'react'
import marketing from '../assets/branding.png'
import md from '../assets/businessman.png'

function About() {
  return (
    <div className='main-conatiner container vh-90'>
        
        <div className="row">
            <div className="col-lg-12">
            <div className="text-center pt-5">
            <h2>OUR TEAM</h2>
        </div>
            </div>
        </div>
        
        <div className="row mb-5 d-flex justify-content-center text-center">
           
            <div className="col-lg-4">
                <div className="card text-center aboutCard">
                <div class="lc-block">
                    <img alt="" class="mb-3 aboutImg"
                     src={md}  loading="lazy" />
				<div editable="rich">
					<p className='mb-4'> "I hold a diploma in Chemical Engineering, specializing in Sugar Technology "</p>
				</div>

			</div>
            <div class="lc-block mt-2 py-2 border-top">
				<h5 editable="inline"><strong>Krishna Reddy</strong></h5>
                
                <small editable="inline" class="text-secondary" >Chemical Engineering</small><br />
				<small editable="inline" class="text-secondary" >Managing Director</small>
			</div>
                </div>

            </div>

            <div className="col-lg-4">
                <div className="card text-center aboutCard">
                <div class="lc-block">
                    <img alt="" class=" mb-3 aboutImg"
                     src={marketing}  loading="lazy" />
				<div editable="rich">
					<p> "I am passionate about learning Ayurvedic and ancient methods to provide a better life for upcoming generations."</p>
				</div>

			</div>
            <div class="lc-block mt-2 py-2 border-top">
				<h5 editable="inline"><strong>Venkatesh Kalasamudram </strong></h5>
                <small editable="inline" class="text-secondary" >M.Phil. (Telugu)</small><br />
				<small editable="inline" class="text-secondary" >Marketing </small>
			</div>
                </div>

            </div>
          
           
            
        </div>
        
        </div>
  )
}

export default About