import React from 'react'
import { Link, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCartstate } from '../../cartSlice';
import './Navbar.css'
import logo from '../../assets/logo.png'

import { BsBag } from 'react-icons/bs'
function Navbar() {

  const cartItems = useSelector(getCartstate);
  let activeClassName = 'active'
  return (
    <>

      <header >

        <div className="navbar navbar-expand-lg navbar-light  fixed-top  App-header">
          <div className="container">
            <Link className="navbar-brand" to="/">
              <img src={logo} alt='iTeaParty' />
            </Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse justify-content-end " id="navbarNav">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">

                  <NavLink
                    className={({ isActive }) =>
                      isActive ? `nav-link ${activeClassName}` : "nav-link"}

                    to="/"
                  >
                    HOME
                  </NavLink>


                </li>
                <li className="nav-item">
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? `nav-link ${activeClassName}` : "nav-link"}

                    to="shop"
                  >
                    SHOP
                  </NavLink>


                </li>
                <li className="nav-item">

                  <NavLink
                    className={({ isActive }) =>
                      isActive ? `nav-link ${activeClassName}` : "nav-link"}

                    to="contact"
                  >
                    CONTACT
                  </NavLink>

                </li>
                <li className="nav-item">

                  <NavLink
                    className={({ isActive }) =>
                      isActive ? `nav-link ${activeClassName}` : "nav-link"}

                    to="about"
                  >
                    ABOUT
                  </NavLink>

                </li>
                <li className="nav-item">

                  <NavLink
                    className={({ isActive }) =>
                      isActive ? `nav-link ${activeClassName}` : "nav-link"}

                    to="cart"
                  >
                    <div className="d-flex">
                      <div className="middle">
                        CART
                      </div>
                      <div className="position-relative">

                        <BsBag className='bag' />
                        <div className="countBadge">{cartItems.length}</div>
                      </div>

                    </div>

                  </NavLink>


                </li>

              </ul>
            </div>
          </div>

        </div>


      </header>

    </>
  )
}

export default Navbar